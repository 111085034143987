import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AddictionAuthModule, AuthService } from 'addiction-auth';
import { authConfig, authModuleConfig } from './core/auth/auth.config';
import { AddictionComponentsModule } from 'addiction-components';
import { authServiceInit } from './core/auth/auth-initializer';
import { AuthJWTInterceptor } from './core/interceptors/auth-jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { provideToastr } from 'ngx-toastr';
import { NavigationService } from './navigation.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideLottieOptions } from 'ngx-lottie';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { firstValueFrom } from 'rxjs';
import player from 'lottie-web';

registerLocaleData(localeIt);

//funzione che ha il compito di caricare il servizio di traduzione
//verrà richiamata dal provider APP_INITIALIZER
function initializeTranslate(){
  const translate = inject(TranslateService);  // Usare inject per ottenere il servizio
  return () => {
    translate.setDefaultLang('it-IT');
    return firstValueFrom(translate.use('it-IT'));  // Restituisci una promise che risolve quando la lingua è pronta
  };
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(appRoutes),
        provideHttpClient(withInterceptors([AuthJWTInterceptor, ErrorInterceptor])),
        provideAnimations(),
        provideLottieOptions({
          player: () => player,
        }),
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpClient],
                },
                defaultLanguage: environment.defaultLocale ?? 'it-IT',
                useDefaultLang: true,
                isolate: false,
            }),
            AddictionAuthModule.forRoot(environment, authConfig, authModuleConfig),
            AddictionComponentsModule.forRoot(environment)
        ),
        { provide: APP_INITIALIZER, useFactory: authServiceInit, deps: [AuthService], multi: true },
        { provide: 'environment', useValue: environment },
        provideToastr({ positionClass: 'toast-bottom-center' }),
        { provide: LOCALE_ID, useValue: 'it-IT' },
        { provide: APP_INITIALIZER, useFactory: () => () => null, deps: [NavigationService], multi: true },
        //in alcune pagine abbiamo avuto un problema con le traduzioni durante il refresh della pagina
        //il problema riscontrato è che il servizio di traduzione non è ancora stato caricato correttamente
        //di conseguenza utilizziamo il provider APP_INITIALIZER che carica il servizio prima che l'applicazione
        //venga bootstrapata evitando così tali problemi
        { provide: APP_INITIALIZER, useFactory: initializeTranslate, deps: [TranslateService], multi: true },
    ],
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
