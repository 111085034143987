import { Routes } from '@angular/router';

export const practiceRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./practice-list/practice-list.component').then((x) => x.PracticeListComponent),
  },
  {
    path: 'new',
    loadComponent: () => import('./practice-editor/practice-editor.component').then((x) => x.PracticeEditorComponent),
  },
  {
    path: 'new/t/:templateUUID',
    loadComponent: () => import('./practice-editor/practice-editor.component').then((x) => x.PracticeEditorComponent),
  },
  {
    path: 'new/u/:patientUUID',
    loadComponent: () => import('./practice-editor/practice-editor.component').then((x) => x.PracticeEditorComponent),
  },
  {
    path: 'edit/:practiceUUID',
    loadComponent: () => import('./practice-editor/practice-editor.component').then((x) => x.PracticeEditorComponent),
  },

  {
    path: 'clone/:practiceUUID',
    loadComponent: () => import('./practice-editor/practice-editor.component').then((x) => x.PracticeEditorComponent),
  },
];
