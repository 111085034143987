import { AfterViewInit, Component, inject, ViewChild, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { AuthService } from 'addiction-auth';
import { filter, switchMap } from 'rxjs';
import { PrintService } from './shared/services/print.service';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit {
  private printService = inject(PrintService);
  @ViewChild('pdfContainer', { read: ViewContainerRef, static: false }) pdfContainer!: ViewContainerRef;

  constructor() {
    const authService = inject(AuthService);
    authService.isDoneLoading$
      .pipe(
        filter((x) => !!x),
        switchMap(() => authService.isAuthenticated$),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (isAuthenticated) => {
          if (!isAuthenticated) {
            authService.login();
          }
        },
      });

  }

  ngAfterViewInit(): void {

    this.printService.setViewContainer(this.pdfContainer);
  }
}
